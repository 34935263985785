import React, { Component } from 'react';
import Layout from '../components/layout';
import Intro from '../components/mask';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import AboutSection from '../components/aboutSection'
import Card from '../components/card'
import IconBullet from '../components/iconbullet'
import ContactForm from '../components/ContactForm'
import { MDBRow, MDBCol, MDBContainer, MDBIcon, MDBBtn } from 'mdbreact'
import wd_badge from "../images/badge.png"



class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="About Me - Amanda Marriage Celebrant" keywords={[`gatsby`, `MDBReact`, `react`, `Material Design For Bootstrap`]} />

          <main>
            <MDBContainer className="my-5">
              <MDBRow>
                <MDBCol md="5" lg="4" className="order-md-last mb-3">
                  <h3>CONTACT DETAILS</h3>
                  <p>phone: <a className="text-info" href="tel:+61403237083">0403237083</a></p>
                  <p>email: <a className="text-info" href="mailto:ceremonies@amanda.net.au">ceremonies@amanda.net.au</a></p>
                  <MDBIcon fab icon="facebook" size="2x" className="blue-text" /><a href="https://facebook.com/amandamarriagecelebrant">Find Me On Facebook</a>
                </MDBCol>
                <MDBCol md="7" lg="8" className="order-md-first">
                  <MDBRow>
                    <MDBCol size="2" lg="1">
                      <MDBIcon icon="check" size="2x" className="cyan-text" />
                    </MDBCol>
                    <MDBCol size="10" lg="11">
                      <p>Does an approachable, accessible, detail orientated, and organised professional resonate with you? Someone with a level of commitment to you that is off the dial.</p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="2" lg="1">
                      <MDBIcon icon="check" size="2x" className="cyan-text" />
                    </MDBCol>
                    <MDBCol size="10" lg="11">
                      <p>Are you looking for a celebrant with the skills to engage your guests, make you feel at ease, and work professionally with other wedding vendors (i.e. photographers)?</p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="2" lg="1"></MDBCol>
                    <MDBCol size="10" lg="11">
                      <h3 className="text-info">YES?</h3>
                      <p>...then I’m likely to be a good fit for you.</p>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <p className="text-center">What I do, I do well.  Service and support matters.  Stay stress free and ENJOY planning your ceremony with me.</p>

                </MDBCol>
              </MDBRow>
              <ContactForm />
            </MDBContainer>
          </main>
        </Layout>
      </>
    );
  }
}

export default App;
