import React, { Children, Component } from 'react'
import {MDBNotification, MDBBox, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon, MDBBadge, MDBContainer, MDBRow, MDBCol, MDBNavbar, MDBNavbarToggler, MDBCollapse, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBDropdownMenu, MDBDropdownItem, MDBDropdown, MDBDropdownToggle, MDBCard, MDBCardHeader, MDBCardBody, MDBListGroup, MDBListGroupItem} from "mdbreact";
import axios from 'axios';
import './ContactForm.css'

function parseDate(date, time) {
    let dateparts = date.split('-');
    let timeparts = time.split(':')
  
    return new Date(dateparts[0], dateparts[1]-1, dateparts[2], timeparts[0], timeparts[1])
  }

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            completed: false,
            iserror: false,
            errortxt: '',
            firstname: '',
            lastname: '',
            fiancefirstname: '',
            fiancelastname: '',
            phonenumber: '',
            email: '',
            ceremonytype: [],
            time: '',
            date: '',
            location: '',
            referral: [],
            message: '',
        }
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
        console.log(nam + ": " + val);
    }

    myMultiChangeHandler = (event) => {
        let nam = event.target.name;
        var options = event.target.options;
        var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              if (options[i].selected) {
                value.push(options[i].value);
              }
            }
            this.setState({[nam]: value});
        console.log(nam + ": " + value);
    }


    handleSubmit = event => {
        event.preventDefault();

        console.log("got form" + this.state.firstname);
        console.log("type: " + this.state.ceremonytype)
        axios.post("https://www.audiobuzz.org/api/enquiryform/", {
            person1: {firstname: this.state.firstname, lastname: this.state.lastname},
            person2: {firstname: this.state.fiancefirstname, lastname: this.state.fiancelastname},
            contact: {phonenumber: this.state.phonenumber, email: this.state.email},
            ceremony: {type: this.state.ceremonytype, address: this.state.location, time: parseDate(this.state.date, this.state.time)},
            referral: this.state.referral,
            message: this.state.message,
        })
        .then(res => {
            console.log(res.status);
            console.log(res.data.Ok);
            if (res.status === 201) {
                console.log("Success!");
                this.setState({
                    completed: true,
                })
            } 

        })
        .catch(error => {
            console.log(error.response)
            this.setState({
                errortxt: error.response.data,
            })
        })
        
        event.target.className += " was-validated";
    }

    render() {
    return (
      <>
        
        <MDBContainer display="flex" className="text-center">
            <h4 className="text-info "> 
                {this.state.completed ? 
                    
                    "Thank you for your submitting your enquiry! I will reply to you as soon as I can!"
                    
                : "Enquire via the form below and I’ll be in touch soon."}</h4> 
        </MDBContainer>
        

        {!this.state.completed && 
            <MDBCard>
                <MDBCardHeader tag="h3">Enquiry Form</MDBCardHeader>
                <MDBCardBody>
                    <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                        <MDBRow>
                            <MDBCol lg="6">
                                <MDBCard>
                                    <MDBCardHeader><h3>You</h3></MDBCardHeader>
                                    <MDBCardBody>
                                        <MDBInput onChange={this.myChangeHandler} type="text" value={this.state.firstname} name="firstname" label="Your given name" required></MDBInput>
                                        <MDBInput onChange={this.myChangeHandler} type="text" name="lastname" label="Your family name" required></MDBInput>
                                        <MDBInput onChange={this.myChangeHandler} type="text" name="phonenumber" label="Your contact number" required></MDBInput>
                                        <MDBInput onChange={this.myChangeHandler} type="email" name="email" label="Your email address" required>
                                            <small id="emailHelp" className="form-text text-muted">
                                                We'll never share your email with anyone else.
                                            </small>
                                        </MDBInput>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBCard>
                                    <MDBCardHeader><h3>Your Partner</h3></MDBCardHeader>
                                    <MDBCardBody>
                                        <MDBInput onChange={this.myChangeHandler} type="text" name="fiancefirstname" label="Your partner's given name"></MDBInput>
                                        <MDBInput onChange={this.myChangeHandler} type="text" name="fiancelastname" label="Your partner's family name"></MDBInput>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        
                        <MDBRow className="my-3">
                            <MDBCol>
                                <MDBCard>
                                    <MDBCardHeader tag="h3">Ceremony Details</MDBCardHeader>
                                    <MDBCardBody>
                                        <MDBRow>
                                            <MDBCol lg="6">
                                                <label htmlFor="CeremonyType">What Ceremony Type Are You Interested In?</label>
                                                <select onChange={this.myMultiChangeHandler} className="form-control" id="ceremonytype" name="ceremonytype" size="7" multiple required>
                                                    <option value="" defaultValue hidden disabled="">
                                                    -- Select an option --
                                                    </option>
                                                    <option value="bespoke">Bespoke Wedding</option>
                                                    <option value="registry">No Fuss Registry Style</option>
                                                    <option value="legals">Just The Legals</option>
                                                    <option value="commitment">Commitment Ceremony</option>
                                                    <option value="vowrenewal">Vow Renewal</option>
                                                    <option value="undecided">Undecided</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </MDBCol>
                                            <MDBCol lg="6">
                                                <MDBRow>
                                                    <label htmlFor="CeremonyDate">Ceremony Date</label>
                                                    <input onChange={this.myChangeHandler} className="form-control" id="CeremonyDate" type="date" name="date" />
                                                </MDBRow>
                                                <MDBRow>
                                                    <label htmlFor="CeremonyStartTime">Ceremony Start Time</label>
                                                    <input onChange={this.myChangeHandler} className="form-control" id="CeremonyStartTime" type="time" name="time" />
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <MDBInput onChange={this.myChangeHandler} type="text" name="location" label="Where are you planning to hold your ceremony?"></MDBInput>
                                                
                                            </MDBCol>{}
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MDBCard>
                                    <MDBCardHeader>Other</MDBCardHeader>
                                    <MDBCardBody>
                                        <MDBRow>
                                            <label htmlFor="Referrals">Where Did You Hear About Me?</label>
                                            <select onChange={this.myMultiChangeHandler} className="form-control" id="Referrals" multiple name="referral" size="11">
                                                <option value="" defaultValue hidden disabled="">-- Select an option --</option>
                                                <option value="Internet Search">Internet Search</option>
                                                <option value="Social Media">Social Media</option>
                                                <option value="Celebrant Society">The Celebrant Society</option>
                                                <option value="Chit Chat">Adelaide Weddings Chit Chat Page</option>
                                                <option value="vendor">Recommended by another vendor (eg photographer, venue, etc)</option>
                                                <option value="Friend or Family">Recommended by a Friend or Family</option>
                                                <option value="attended a wedding">I attended a wedding where you were the celebrant</option>
                                                <option value="Unbridely">Unbridely</option>
                                                <option value="Easy Weddings">Easy Weddings</option>
                                                <option value="Expo">Expo</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <MDBInput onChange={this.myChangeHandler}  type="textarea" label="Tell me a little more about what you're looking for" rows="5" name="message" />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                       
                        
                            <MDBRow end className="align-items-center">
                                <MDBCol md="9" className="text-center text-md-right">
                                <span className="red-text">{this.state.errortxt}</span>
                                </MDBCol>

                                <MDBCol md="3" lg="2" className="text-center">
                                    <MDBBtn color={this.state.completed ? "red" : "cyan"} type='submit'>Submit</MDBBtn>
                                </MDBCol>
                                
                                
                                
                            </MDBRow>
                            
                    </form>
                </MDBCardBody>
            </MDBCard>
            }
        
        <MDBContainer>

        </MDBContainer>
      </>
        );
    }
}
      
export default ContactForm;